// 🛑 NOTICE: __generated__ folders should be added to .gitignore
// 🛑 In this repo I keep generated files only for demo purposes!
import * as Types from '../../../__generated__/types';

import { DocumentNode } from 'graphql';
import { ConnectionSessionWA } from '../../fragments/__generated__/ConnectionSessionWA.fragment';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type ConnectionSessionWAListVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.FilterFindManyWhatsappInput>;
}>;


export type ConnectionSessionWAList = { __typename: 'Query', connectionSessionWAList?: Array<{ __typename: 'ClientStatus', statusWbot?: string | null, percent?: number | null, notification?: string | null, needPhone?: string | null, nameVitrualMachine?: string | null, linkCode?: string | null, versionCode?: string | null, waClient?: { __typename: 'ClientStatusWaClient', _id?: any | null, name?: string | null, phone?: number | null, email?: string | null, unreadCount?: number | null, status?: Types.EnumClientStatusWaClientStatus | null, needConnectQrCode?: boolean | null, profilePicUrl?: string | null, host?: string | null, retries?: number | null, qrcode?: string | null, novoreg_date?: any | null, progrev_good?: boolean | null, allCount?: number | null } | null } | null> | null };


export const ConnectionSessionWAListDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ConnectionSessionWAList"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"filter"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"FilterFindManyWhatsappInput"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"connectionSessionWAList"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"filter"},"value":{"kind":"Variable","name":{"kind":"Name","value":"filter"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ConnectionSessionWA"}}]}}]}},...ConnectionSessionWA.definitions]} as unknown as DocumentNode;

/**
 * __useConnectionSessionWAList__
 *
 * To run a query within a React component, call `useConnectionSessionWAList` and pass it any options that fit your needs.
 * When your component renders, `useConnectionSessionWAList` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConnectionSessionWAList({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useConnectionSessionWAList(baseOptions?: ApolloReactHooks.QueryHookOptions<ConnectionSessionWAList, ConnectionSessionWAListVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<ConnectionSessionWAList, ConnectionSessionWAListVariables>(ConnectionSessionWAListDocument, options);
      }
export function useConnectionSessionWAListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ConnectionSessionWAList, ConnectionSessionWAListVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<ConnectionSessionWAList, ConnectionSessionWAListVariables>(ConnectionSessionWAListDocument, options);
        }
export type ConnectionSessionWAListHookResult = ReturnType<typeof useConnectionSessionWAList>;
export type ConnectionSessionWAListLazyQueryHookResult = ReturnType<typeof useConnectionSessionWAListLazyQuery>;
export type ConnectionSessionWAListQueryResult = Apollo.QueryResult<ConnectionSessionWAList, ConnectionSessionWAListVariables>;