import { useFullscreen } from 'app/providers/fullscreenProvider/fullscreenProvider';
import { useUserRole } from 'app/providers/userProvider/userProvider';
import SideMenu from 'shared/components/sideMenu';
import Header from 'widgets/header';

export default function Layout({ children }: { children: React.ReactNode }) {
  const { userRole, userOrg } = useUserRole();
  const { fullscreen } = useFullscreen();
  const isOpgt = userOrg === 'AC_OPGT';

  return (
    <>
      <Header />
      <main className="flex h-[calc(100vh-110px)] w-full gap-x-2 px-[1%] xl:gap-x-6">
        {userRole && !fullscreen && !isOpgt && (
          <div className="pageBlock">
            <SideMenu />
          </div>
        )}
        <div className="flex w-full flex-row gap-x-2 xl:gap-x-6">{children}</div>
      </main>
    </>
  );
}
